import { Injectable } from '@angular/core';
import { BaseRequestService } from './base-request.service';
import { BehaviorSubject, Observable, lastValueFrom, map } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { LocalStorageEnum } from '../models/enums/local-storage-enum';
import { NavigationStart, Router } from '@angular/router';
import { UserRoleEnum } from '../models/enums/user-role-enum';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authChange$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    this._isAuth
  );
  isAuth: boolean = this._isAuth;
  userRole: string = this._userRole;
  userId: string = this._userId;

  constructor(
    private _baseRequestService: BaseRequestService,
    private _localStorageService: LocalStorageService,
    private _router: Router
  ) {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.isAuth != this._isAuth) {
          this.markStatusChange();
        }
      }
    });
  }

  login(data: { email: string; password: string }) {
    return lastValueFrom(
      this._baseRequestService
        .postJSON('/login', {
          data,
          is_loading: true,
        })
        .pipe(
          map((res: any) => {
            console.log('login res', res);

            this._localStorageService.set(
              LocalStorageEnum.token,
              res.data.token
            );
            this._localStorageService.set(
              LocalStorageEnum.token_expires_at,
              this._getTokenExpiresAt(res.data.expires_in).toString()
            );
            this._localStorageService.set(
              LocalStorageEnum.user_role,
              res.data.user.role
            );
            this._localStorageService.set(
              LocalStorageEnum.user_profile,
              res.data.user.profile
            );

            this._localStorageService.set(
              LocalStorageEnum.user_firstname,
              res.data.user.first_name
            );
            this._localStorageService.set(
              LocalStorageEnum.user_lastname,
              res.data.user.last_name
            );
            this._localStorageService.set(
              LocalStorageEnum.user_id,
              res.data.user._id!
            );
            this._localStorageService.set(
              LocalStorageEnum.refresh_token,
              res.data.refresh_token
            );

            if (res.data.user.role === UserRoleEnum.Requester) {
              this._localStorageService.set(
                LocalStorageEnum.user_division_name,
                res.data.user.division.name
              );

              this._localStorageService.set(
                LocalStorageEnum.user_division_id,
                res.data.user.division?._id
              );
            }

            this.markStatusChange();
            return res;
          })
        )
    );
  }

  logout() {
    this._localStorageService.delete(LocalStorageEnum.token);
    this._localStorageService.delete(LocalStorageEnum.user_id);
    this._router.navigate(['/login']);
    return new Observable<string>((observer) => {
      observer.complete(); // complete function will be called when the observable is complete
      this.markStatusChange();
    });
  }

  private markStatusChange() {
    this.authChange$.next(this._isAuth);
    this.isAuth = this._isAuth;
    this.userRole = this._userRole;
    this.userId = this._userId;
  }

  private _getTokenExpiresAt = (tokenExpiresIn: number): number => {
    const currentDate = new Date();
    const currentMillisecond = currentDate.getTime();
    // convert to millisecond since response from server is in second
    const tokenExpiresInMillsecond = tokenExpiresIn * 1000;
    return currentMillisecond + tokenExpiresInMillsecond;
  };

  private get _isAuth(): boolean {
    if (this._localStorageService.get(LocalStorageEnum.token)) {
      return true;
    }
    return false;
  }

  private get _userRole(): string {
    return this._localStorageService.get(LocalStorageEnum.user_role);
  }

  private get _userId(): string {
    return this._localStorageService.get(LocalStorageEnum.user_id);
  }

  public get _tokenExpired(): boolean {
    const currentDate = new Date();
    return (
      Number(this._localStorageService.get(LocalStorageEnum.token_expires_at)) <
      currentDate.getTime()
    );
  }
}
